.container{
    margin:50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ik, .ir{
    width:70px !important;
    height:70px !important;
}
.txt{
    margin:20px 0 0 10px !important;
    font-size:18px !important;
}