body {
    background: linear-gradient(rgba(255, 255, 255, 0.8) ,rgba(255, 255, 255, 0.8)) ,  url(back.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
}
.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.image {
    width: 35%;
    height: auto;
}

@media (max-width: 770px) {
    .container {
        margin: 0 10%;
    }
    .image {
        width: 100%;
    }
}