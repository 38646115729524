.container {
    display: flex;
    justify-content: center;
    width: 65%;
    background-color: white;
    padding: 10px;
}
@media (max-width: 770px) { 
    .container {
        width: 100%;
    }
}